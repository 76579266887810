import { styled } from '@mui/material/styles';
import { Theme } from "@mui/material/styles";
import { ServerErrorResponse } from '../../adapters/ApiSchema';
import { ErrorInterface } from '../../store/error-slice';

const PREFIX = 'Error';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')((
  {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    color: "red",
    textAlign: "center",
    fontWeight: "bold",
  }
}));

const Error = (props: { title: string, error?: ServerErrorResponse | ErrorInterface }) => {

  let message = props.error?.Message;
  let status = (props.error as ServerErrorResponse)?.StatusCode || (props.error as ErrorInterface)?.status;
  let customText = (props.error && "errorType" in props.error) ? props.error.errorType : (props.error && "ExceptionType" in props.error) ? props.error.ExceptionType : "Unknown";

  return (
    <Root className={classes.root + ' tokenError'} data-error={JSON.stringify(props)}>
      <p data-error={JSON.stringify(props.error)}>{props.title + ": " + (message || "no message") + "- " + status + ":" + (customText || "no message")}</p>
    </Root>
  );
};
export default Error;
