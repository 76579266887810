

import {
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    Paper,
    InputBase,
    FormHelperText
} from '@mui/material';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import {
    Search as SearchIcon,
    HighlightOff as XIcon
} from '@mui/icons-material';


import { createLSFeatureFlag } from "../util/storage";
import { System, debounce } from "../Reusable";
import { SearchInvokeAsync, ServerErrorResponse, isServerErrorResponse } from "../adapters/ApiSchema";
import { queryKnownMatchMedia } from "../adapters/windowUtils";
import { withPreventDefault } from "../adapters/Eventing";
import React from "react";

export const contentSearchFeatureFlagKey = "allowContentSearch";

const contentSearchFeatureFlag = createLSFeatureFlag(contentSearchFeatureFlagKey, true, true);


export type ContentSearchProps = {
    focus: System.PropWrapper<boolean>
    cardClassName: string
    categories?: string[]
    onSearch: SearchInvokeAsync
}

// nice to have: server flag forcing content search off
export const ContentSearch = ({ cardClassName, focus: [isFocus, setIsFocus], onSearch }: ContentSearchProps) => {


    const [lastSearch, setLastSearch] = useState<string>("");
    const [searchText, setSearchText] = useState<string>(lastSearch ?? "");
    const [awaitingSearchResult, setAwaitingSearchResult] = useState<boolean>(false);

    let isSmallDevice = queryKnownMatchMedia('isSmallDevice') ?? false;
    // console.log('rerendering onSearch')

    // WARN: this may be under minding the search loading indicator and wait
    let onClick = async () => {
        setAwaitingSearchResult(true);
        let result = await onSearch(searchText);
        if (!isServerErrorResponse(result)) {
            console.log('storing')
            setLastSearch(searchText);
        }
        setAwaitingSearchResult(false);
    };

    let refocus = useCallback(debounce(() => setIsFocus(true)), [])

    let shouldShowHelper: boolean = lastSearch !== '';

    let showContentSearch = contentSearchFeatureFlag.getter();

    if (showContentSearch !== true) {
        return (<div />);
    }

    // if the search is empty and is not 1-2 chars
    let hasValidSearch = searchText === "" || (searchText && searchText.length && (searchText.length > 2 || false));
    let hasValidLastSearch = !System.String.isNullOrEmpty(lastSearch);

    let searchDisabled: boolean = awaitingSearchResult || lastSearch === searchText || !hasValidSearch || (!hasValidLastSearch && searchText === "") || false;

    // console.log('CS', { awaitingSearchResult, lastSearch, searchText, hasValidSearch, hasValidLastSearch, searchDisabled });
    // https://mui.com/material-ui/react-text-field/
    return ( // using component form to capture enter key
        <Paper
            component={'form'}
            onSubmit={withPreventDefault(onClick)}
            elevation={14}
            className={cardClassName}
            sx={{ p: '2px 4px', height: isFocus ? '46px' : isSmallDevice ? undefined : '40px', display: 'flex', alignItems: 'center', width: isFocus ? 505 : 350, marginTop: '5px', marginBottom: '5px' }}
        >
            {awaitingSearchResult ? <div>loading...</div> :
                <React.Fragment>

                    <IconButton disabled={searchText === ""} title={"Clear Search"} type="button" sx={{ p: '10px' }} onClick={() => { setSearchText(""); refocus({}); }} aria-label="search">
                        <XIcon />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search OneView Home"
                        aria-describedby={shouldShowHelper ? 'my-helper-text' : ''}
                        inputProps={{ 'aria-label': 'search oneView home' }}
                        value={searchText}
                        autoFocus={isFocus}
                        onFocus={() => setIsFocus(true)}
                        onBlur={() => setIsFocus(false)}
                        onChange={(e) => (setSearchText(e.target.value))}
                    />
                    {shouldShowHelper ?
                        <FormHelperText id="my-helper-text" onClick={() => refocus(true)} >'{lastSearch}' Search Results</FormHelperText > : ''}
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled={searchDisabled} onClick={onClick}>
                        <SearchIcon />
                    </IconButton>
                </React.Fragment>
            }
        </Paper>
    );
}