import { AxiosResponse } from "axios"
import { System } from "../Reusable"

export type TryAxiosResponse<T> = AxiosResponse<T | ServerErrorResponse> | undefined

// based on CoreLib.Exceptions.CoreApiError
export type ServerErrorResponse = {

    Message: string
    ExceptionMessage?: string
    ExceptionType?: string
    InnerException?: string
    MessageDetail?: string
    StackTrace?: string
    StatusCode?: number
    StatusMessage?: string
    Url?: string
    User?: string
}

export type SearchInvokeResult = void | ServerErrorResponse
export type SearchInvokeAsync = System.Func1<string, Promise<SearchInvokeResult>>

// could incorrectly assert an ErrorInterface is a ServerErrorResponse

export function isServerErrorResponse(value: any): value is ServerErrorResponse {
    // assuming this is a good test
    return !!value?.Message && (!!value.ExceptionMessage || !!value?.StackTrace || !!value?.StatusCode);
}

export interface IProfileService {
    deleteStudents(stuId: string): Promise<JustMessage | ServerErrorResponse>
    get365Apps(): Promise<O365AppResponse | ServerErrorResponse>
    getContactInfo(): Promise<UserContactInfo | ServerErrorResponse>
    getDeviceLogs(logUrl: string): Promise<DeviceLog[] | ServerErrorResponse>
    getMyDevices(): Promise<IDevice[] | ServerErrorResponse>
    getMyStudentDevices(): Promise<IStudentWithDevices[] | ServerErrorResponse>
    getPasswordPolicy(): Promise<string | ServerErrorResponse>
    getPhoneHelp(): Promise<string | ServerErrorResponse>
    getStatesInfo(): Promise<unknown | ServerErrorResponse>
    getStudentAddRequests(): Promise<unknown[] | ServerErrorResponse>
    getStudentAssignments(stuId: string, courseId: string): Promise<StudentAssignment[] | ServerErrorResponse>
    getStudentAttendance(periodId: string, stuId: string): Promise<StudentAttendance[] | ServerErrorResponse>
    getStudentContactInfo(studentId: string): Promise<StudentContactInfo[] | ServerErrorResponse>
    getStudentGrades(): Promise<unknown | ServerErrorResponse>
    getStudentInfo(): Promise<StudentInfo[] | ServerErrorResponse>
    getStudents(): Promise<Student[] | ServerErrorResponse>
    getUserProfileInfo(): Promise<UserInterface | ServerErrorResponse>
    getUserSchools(): Promise<School[] | ServerErrorResponse>
    postCheckPassword(passwordEntered: string): Promise<CheckPasswordResponse | ServerErrorResponse>
    postContactInfo(userData: Partial<UserContactInfo>): Promise<UserContactInfo | ServerErrorResponse>
    postEmailRename(email: string): Promise<unknown | ServerErrorResponse>
    postStudents(studentIDPassed: string, studentDobPassed: string, studentLastName: string): Promise<JustMessage | ServerErrorResponse>
    postVerifyPhoneNumber(id: string, code: string): Promise<JustMessage | ServerErrorResponse>
    saveNewPassword(currentPassword: string, newPassword: string): Promise<JustMessage | ServerErrorResponse>
    verifyAvailableEmail(email: string): Promise<VerifyAvailableEmailResponse | ServerErrorResponse>
    verifyPhoneNumber(): Promise<VerifyPhoneNumberResponse | ServerErrorResponse>
}

export interface JustMessage {
    Message: string
}

export interface DeviceLog {
    onPrem: boolean
    deviceName: string
    createdDateTime: any
    city: string
    state: string
    latitude: any
    longitude: any
}
export interface VerifyPhoneNumberResponse {
    Message: string
    ResponseId: string
}

export interface O365App {
    Title: string
    Url: string
    Icon: string
}

export interface O365AppResponse {
    Apps: O365App[]
}

export interface UserInterface {
    AccountType: string;
    CanChangePassword: boolean;
    CanEditProfile: boolean;
    DisplayName: any; // may always be string
    Email: any; // may always be string
    ExternalPasswordChangeUrl?: string;
    ExternalProfileUrl?: string;
    FirstName: string;
    HasDevices: boolean;
    HasExternalPasswordChangeUrl: boolean;
    HasExternalProfileUrl: boolean;
    HasJobDescription: boolean;
    HasStudentContactInfo: boolean;
    HasStudentDevices: boolean;
    HasStudentInfo: boolean;
    IsImperator: boolean;
    JobDescription: string;
    LastName: string;
    MyDeviceCount: number;
    ObjectID: string; // may always be guid string
    PhotoUrl: any; // may always be string - url
    StudentContactCount: number;
    StudentCount: number;
    StudentDeviceCount: number;
    StudentIds: any; // may always be ?[]
    UserPrincipalName: string;
    dcpsAUPAcceptanceDate: string;
    dcpsID: string;
    dcpsType: string;
    extranetGUID: string;
}

export type Guid = string

export type IStudentWithDevices = {
    AssignedDevices: IDevice[]
    DateOfBirth: string
    DisplayName: string
    FirstName: string
    GradeLevel: string
    Id: string
    LastName: string
    PhotoUrl: string
    UserPrincipalName: string
    extranetGUID: string
    student_id: number
}

export type ITicket = {
    AssetId: string
    Assigned_Group: string
    Assigned_Support_Organization: string
    Description: string
    Incident_Number: string
    Last_Resolved_Date: string
    Resolution: string | null
    Status: string
    SubmitDate: string
    Submitter: string
}
export type IDevice = {
    Id: Guid,
    Model: string,
    Manufacturer: string,
    SerialNumber: string,
    Region: string,
    SiteGroup: string,
    Site: string,
    Item: string,
    Name: string,
    Type: string,
    DeviceName: string,
    HasLogs: boolean,
    AssetId: string,
    Classification: string,
    Description: string,
    PurchaseCost: number,
    MarketValue: number,
    ESports: boolean,
    Title1: boolean,
    CareerTechEdu: boolean,
    Spare: boolean,
    ECF: boolean,
    Lab: boolean,
    AssetLifecycleStatus: string,
    StatusReason: string,
    SapStatus: string,
    LastScanDate: string,
    LoginName: string,
    dcpsType: string,
    dcpsID: string,
    IsComputer: boolean,
    IsStudentDevice: boolean,
    DeviceLogonsUrl: string
    Tickets?: ITicket[]
}
export type StateInfo = {
    Abbreviation: string
    Name: string
}
export type UserContactInfo = {
    Address: string
    ApartmentNumber: string
    City: string
    EmailAddress: string
    FirstName: string
    IsMobilePhone: boolean
    LastName: string
    MobilePhone: string
    PostalCode: string
    SecondaryEmailAddress?: string
    State: string
    MobilePhoneInfo: { IsVerified: boolean }
}

export type CheckPasswordResponse = {
    Valid: boolean
    Password: string
    Violations: string[]
}
export type VerifyAvailableEmailResponse = {
    Accepted: boolean
    Message: string
    Email: string
}

// fetched from /api/profile/students via webAPIEndPointStudents
export type Student = {
    Id: string
    PhotoUrl: string
    DisplayName: string
    GradeLevel: string
    DateOfBirth: string
}

export type StudentAssignment = {
    assignment_title: string
    description: string
    due_date: string
    publish_date: string
    no_late_turnin: string // cspell:disable-line
    letter_grade: string
    points: string
    comments: string
}

export type StudentAttendance = {
    title: string
    period: string
    school_date: string
}

export type StudentInfoGrade = {
    start_date: string // like "2023-08-14T00:00:00"
    end_date: string
    period_id: string
    student_id: string
    course_period_id: string
    period: string
    course_title: string
    teacher: string
    percent_grade: number
    credits: number
    gpa_points: number
    grade_title: string
    attendance_item_count: number
    assignment_count: number
}

// fetched from /api/profile/myStudentInfo
// does not perfectly reflect Student type
// commenting out things we aren't using even if they are on the data currently
export type StudentInfo = {
    Id: string
    DisplayName: string
    GradeLevel: string
    // FirstName: string
    // LastName: string
    PhotoUrl: string
    DateOfBirth: string
    CurrentGrades: StudentInfoGrade[]
}


export type StudentContactInfoInfo = {
    id: string
    title: string
    value: any
}

export type StudentContactInfoContact = {
    person_id: string
    first_name: string
    last_name: string
    student_relation?: string
    email: string
    emergency?: string
    custody?: string
    contact_info: StudentContactInfoInfo[]
    address?: { address: string, city: string, state: string, zipcode: string } // cspell:ignore: zipcode
}

export type StudentContactInfo = {
    contacts: StudentContactInfoContact[]
}

export type School = {
    SortedRole: string
}