// adapter for window stuff that needs special handling in special cases

// we have a situation where iphone dcps app is doing nothing when links are clicked
export function windowOpen(url: string, target?: string | undefined, features?: string | undefined) {
    // window.open(url, target, features);
    // TODO: log this to SEQ
    const ua = window.navigator.userAgent;
    if (ua.includes("AppleWebKit") && (ua.includes("iPhone") || ua.includes("iPad")) && !ua.includes("Version")) {
        console.warn("Using apple web kit workaround");
        window.location.href = url;
        return;

    }
    try {
        // checking window.open's return value for null failed to test on localhost
        window.open(url, target, features);
    } catch (ex) {
        // TODO: log this to SEQ
        console.error("Failed to call window.open");
        window.location.href = url;
    }
}

export function isWindowBottom() {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + Math.ceil(window.scrollY + 1);
    return windowBottom >= docHeight;
}

export function getComputedStylePropertyValue(element: HTMLElement, styleProp: string) {
    let computedStyle = element && 'getComputedStyle' in window ? window.getComputedStyle(element) : undefined;
    if (computedStyle === undefined) return;

    return computedStyle.getPropertyValue(styleProp);
}


export type KnownMedia =
    | 'isSmallDevice'
    | 'isDark'

export function queryKnownMatchMedia(knownMedia: KnownMedia) {
    switch (knownMedia) {
        case "isSmallDevice": // (useRefHeightMeasure): for elements that can react automatically to size changes see: https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
            return matchMedia('(max-width: 600px)');
        case "isDark":
            return matchMedia('(prefers-color-scheme: dark)');
    }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
export function matchMedia(mediaQuery: string): boolean | undefined {
    return "matchMedia" in window ? window.matchMedia(mediaQuery).matches : undefined;
}