// opt out to meet microsoft requirement

import { TextField } from '@mui/material';
import { useState } from 'react';
import { System } from '../Reusable';
import { PrimaryButton } from '@fluentui/react';


// export const RenameComponent = ({ username }: { username: string }) => {

export const OptOutComponent = ({ isIn }: { isIn: boolean }) => {

    const label = isIn ? "Consent" : "Phone number to opt-out";
    const textLabel = isIn ? "Phone Number" : "Existing Phone Number";
    const legalese = isIn ? "By submitting this form you consent to receive text messages" : ""
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [validPhone, setValidPhone] = useState<string>("");
    const [optStatus, setOptStatus] = useState<string>("");


    const validatePhone = (value: string) => {
        if (/[0-9]{10}/.test(value)) {
            const formattedPhone = '(' + value.substring(0, 3) + ') ' + value.substring(3, 6) + ' - ' + value.substring(6);
            setValidPhone(formattedPhone);
        }
        setPhoneNumber(value);
    };
    return (<div>
        <h2>{label}</h2>
        <div>
            <div>
                <TextField
                    sx={{ width: 350 }}
                    id="disabled-input-with-sx"
                    autoFocus
                    onChange={e => validatePhone(e.target.value)}
                    defaultValue={phoneNumber}
                    label={textLabel}
                    variant="standard"
                />
            </div>
            <div>{validPhone}</div>
            <div><p>{legalese}</p></div>
            <div>{optStatus}</div>
            <div
                className={
                    System.String.isNullOrWhitespace(optStatus) ? "" : "ErrorMessage"
                }
            > {optStatus}
            </div>
            <PrimaryButton
                className="fluentUI primaryButton"
                onClick={() => { setOptStatus("Phone Number not found") }}
                style={{ padding: 5, margin: 5 }}
                color="primary"
            >
                Submit
            </PrimaryButton>
        </div>


    </div>);
}