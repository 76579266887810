// cSpell:ignore addstudent, changepassword, contactinfo, mylinks
import React, { useCallback, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, TextField, Card, CardActionArea, Typography, Skeleton } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import defaultStore from '../../util/Globale';
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";
import "../../style/App.css";
import * as AllLinkIcons from '@mui/icons-material';

import { equalsCI } from "../../Reusable";
import { MenuItem } from "../../store/menu-slice";
import { windowOpen } from "../../adapters/windowUtils";

const PREFIX = 'LinksComponent';

const classes = {
    grid: `${PREFIX}-grid`,
    button: `${PREFIX}-button`,
    skeleton: `${PREFIX}-skeleton`,
    buttonAction: `${PREFIX}-buttonAction`,
    icon: `${PREFIX}-icon`,
    blendedLearning: `${PREFIX}-blendedLearning`
};

// TODO jss-to-styled code mod: The Fragment root was replaced by div. Change the tag if needed.
const makeRoot = (gridTemplateOption: string) => styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.grid}`]: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        paddingTop: 40,
        paddingBottom: 20,
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
        },
    },

    [`& .${classes.button}`]: {
        margin: 5,
    },

    [`& .${classes.skeleton}`]: {
        '&.MuiBox-root': {
            height: CARD_HEIGHT_LG,
            [theme.breakpoints.between('sm', 'lg')]: {
                height: CARD_HEIGHT_SM,
            }
        }
    },

    [`& .${classes.buttonAction}`]: {
        display: 'grid',
        gridTemplateColumns: gridTemplateOption,
        backgroundColor: 'white',
        padding: 20,
        height: CARD_HEIGHT_LG,
        [theme.breakpoints.between('sm', 'lg')]: {
            height: CARD_HEIGHT_SM,
        }
    },

    [`& .${classes.icon}`]: {
        fill: theme.palette.primary.main,
        justifySelf: 'end',
        marginRight: 20,
        transform: "scale(1.2)",
    },

    [`& .${classes.blendedLearning}`]: {
        marginLeft: 'auto',
    }
}));

const CARD_HEIGHT_SM = 150;
const CARD_HEIGHT_LG = 75;

interface LinkItem {
    navType: any
    url: any
    // script: any
    name: any
    description: any
    icon: any
    acl: any
    properties?: any
    urlKey?: any
}

interface IItems { loading: boolean, data: LinkItem[], hasBlendedLearning: boolean }

const LinksComponent = ({ category }: { category: string }) => {
    // console.log('linksComponent', category);
    const initialState = { loading: true, data: [], hasBlendedLearning: false }
    const [gridTemplateOption, setGridTemplateOption] = useState<string>("1fr 3fr");
    const Root = makeRoot(gridTemplateOption)

    const navigate = useNavigate();
    const menu = useSelector((state: RootState) => state.menu.menu);
    const [items, setItems] = useState<IItems>(initialState);
    const [original, setOriginal] = useState<LinkItem[]>([])
    const [filter, setFilter] = useState<string>("");
    const { t: translate } = useTranslation();
    const dummy = new Array(18).fill(0);


    const getItems = useCallback((menu: MenuItem[]) => {
        var _items: LinkItem[] = [];
        for (var i = 0; i < menu.length; i++) {
            let currentMenu = menu[i]
            let currentMenuItems = currentMenu.Items || [];
            if (currentMenu.Name === category) {

                for (var j = 0; j < currentMenuItems.length; j++) {

                    let item = currentMenuItems[j];
                    if (item.Enabled === false) continue;

                    let _item: LinkItem = {
                        navType: item.Type,
                        url: item.Url,
                        // script: item["Script"],
                        name: item.Name,
                        description: item.Description,
                        icon: getIcon(item.Icon),
                        acl: item.Acls,
                        urlKey: item.UrlKey
                    };

                    _items.push(_item);
                }
                if (category === "Blended Learning") {
                    setItems({ loading: false, data: _items, hasBlendedLearning: true });
                    setOriginal(_items);
                    setGridTemplateOption("");
                    return _items;
                }
            }
            setItems({ loading: false, data: _items, hasBlendedLearning: false });
            setOriginal(_items);
            setGridTemplateOption("1fr 3fr");
        }
        return _items
    }, [category])

    useEffect(() => {
        setItems({ ...initialState });
        getItems(menu);
    }, [getItems, menu])

    const onFilter = (text: string) => {
        setFilter(text);
        setItems({ ...items, data: original.filter((elem) => elem.name.toLowerCase().indexOf(text.toLowerCase()) > -1) });
    }

    const handleLinkClick = (item: LinkItem) => {
        if (item.urlKey) {
            if (equalsCI(item.urlKey, "/pomAdmin")) {
                windowOpen(defaultStore.pomAdmin);
            }
            else if (equalsCI(item.urlKey, "/ferpa")) {
                windowOpen(defaultStore.ferpa);
            }
            else {
                navigate("/myLinks" + item.urlKey);
            }
        }
        else {
            if (isValidHttpUrl(item.url)) {
                // https://stackoverflow.com/questions/40593632/use-window-open-but-block-use-of-window-opener
                const windowFeatures = "noopener"
                windowOpen(item.url, undefined, windowFeatures);
            } else {
                // TODO: log this to SEQ
                console.error("invalid url found:" + item.url);
            }
        }
    }

    const getIcon = (name: any) => {
        let SelectedIcon = AllLinkIcons[name as keyof typeof AllLinkIcons]
        // console.log(icon);
        if (SelectedIcon === undefined) {
            SelectedIcon = AllLinkIcons.FiberNew;
        }
        return <SelectedIcon />
    }

    function isValidHttpUrl(maybeUrl: string) {
        let url;

        try {
            url = new URL(maybeUrl);
        } catch (ex) {
            // TODO: log this to SEQ
            console.warn("invalid url:" + maybeUrl, ex);
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    var filterText = "Filter Links";

    if (category === "Blended Learning") {
        filterText = translate('BlendedLearning.Filter');
    }
    let textSearchField =
        (<TextField sx={{ width: 350 }}
            key={'key' + filter}
            id="search-input-with-sx"
            autoFocus
            onChange={(e) => (onFilter(e.target.value))} value={filter} label={filterText} variant="standard" />);

    return (
        (<Root className="LinksComponent_LinksComponent" data-file="LinksComponent">
            {!items.loading && (
                <Box id="linksLoading" sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    {textSearchField}
                </Box>)}
            {items.loading && (
                <Skeleton animation="wave" variant="rectangular" width="50%" />

            )}
            {!items.hasBlendedLearning && category === "Blended Learning" && (
                <div>
                    <p style={{ textAlign: "center" }}>{translate("BlendedLearning.text")}</p>
                </div>
            )}
            <Box id="linksComponentBox" className={classes.grid}>
                {items.loading &&
                    dummy.map((_, index) => {
                        return (
                            <Box
                                className={`${classes.button} ${classes.skeleton}`}
                                key={index}
                            >
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width="100%"
                                    height="100%"
                                />
                            </Box>
                        );
                    })}
                {items.data.map((item: LinkItem, index) => {
                    // sanitizing out acls
                    let diagDisplay = Object.assign({}, item);
                    delete diagDisplay.acl;
                    // TODO: log this to SEQ
                    if (!items.loading && !item.urlKey && !isValidHttpUrl(item.url)) {
                        // can we render differently?
                        return (<React.Fragment key={index}>
                            <a href={item.url}>{item.name}</a>
                        </React.Fragment>);
                    }
                    return (<React.Fragment key={index}>
                        {!items.loading && <Card variant="outlined" className={classes.button}>
                            <CardActionArea
                                className={classes.buttonAction}
                                data-item={JSON.stringify(diagDisplay)}
                                key={item.name}
                                title={item.url}
                                onClick={() => handleLinkClick(item)}>
                                {category !== "Blended Learning" && (
                                    <>
                                        {React.cloneElement(item.icon, { className: classes.icon })}
                                        <Typography variant="button">
                                            {item.name}
                                        </Typography>
                                    </>

                                )}
                                {category === "Blended Learning" && (
                                    <Typography variant="button" className={classes.blendedLearning}>
                                        {item.name}
                                    </Typography>
                                )}
                            </CardActionArea>
                        </Card>}
                    </React.Fragment>);
                })}

            </Box>
        </Root>)
    );
}

export default LinksComponent

