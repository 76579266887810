// cSpell:ignore addstudent, changepassword, contactinfo, mylinks
import React, { useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate, Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useMsal } from "@azure/msal-react";
import { useTheme } from "@mui/material/styles";
import { queryKnownMatchMedia, windowOpen } from "../../adapters/windowUtils";

import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Tooltip,
  Drawer,
  Divider,
  ClickAwayListener
} from "@mui/material";

import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  FluentUI,
  // DocumentCardActivity,
  IDocumentCardActivityPerson,
} from "../../adapters/fluentUI";

import { OneViewWhite } from "../../img/Icons";
import TokenError from "../../pages/Exceptions/TokenError";
import AppNotifications from "./Notifications";

import LeftNav from "./LeftNav";
import useEventListener from "../../util/useEventListener";
import { useTranslation } from "react-i18next";
import { usePullRight, useSwipeLeft } from "../../util/useSwipe";
import { IPublicClientApplication } from "@azure/msal-browser";

import { withEventTarget } from "../../adapters/Eventing";
import { createLSFeatureFlag } from "../../util/storage";
import { ContentSearch, ContentSearchProps } from "../ContentSearch";
import { SearchEditorProps } from "../../pages/Home/CDS";

const PREFIX = 'AppNavBar';

// const authDiag = getFromLS<boolean>("authDiag") || false;
export const doAuthDiagFeatureFlagKey = "authDiag";
const authDiag = createLSFeatureFlag(doAuthDiagFeatureFlagKey, true, false);


const classes = {
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  homeSearchComponent: `${PREFIX}-homeSearchComponent`,
  drawerHeaderTitle: `${PREFIX}-drawerHeaderTitle`,
  hide: `${PREFIX}-hide`,
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  toolbarControls: `${PREFIX}-toolbarControls`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  grow: `${PREFIX}-grow`,
  title: `${PREFIX}-title`,
  sectionDesktop: `${PREFIX}-sectionDesktop`,
  sectionMobile: `${PREFIX}-sectionMobile`,
  nested: `${PREFIX}-nested`,
  menuItem: `${PREFIX}-menuItem`
};

// TODO jss-to-styled code mod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.appBar}`]: {
    backgroundColor: theme.palette.primary.dark,

    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.toolbar}`]: {
    minHeight: 60,
    padding: "0 20px",
  },

  [`& .${classes.appBarShift}`]: {
    marginLeft: drawerWidth,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    color: "#ffffff",
  },

  [`& .${classes.homeSearchComponent}`]: {
    marginRight: theme.spacing(2),
    color: "#ffffff",
    marginLeft: '10px',
    padding: '2px',
  },

  [`& .${classes.drawerHeaderTitle}`]: {
    backgroundColor: "transparent",
    marginLeft: theme.spacing(1),
    marginBottom: 4, //align logo
    padding: 0,
  },

  [`& .${classes.hide}`]: {
    display: "none",
  },

  [`& .${classes.drawer}`]: {
    width: 0,
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
    },
  },

  [`& .${classes.drawerOpen}`]: {
    width: drawerWidth,
    transition: theme.transitions.create(["width", "background-color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter, //"300ms"
    }),
    backgroundColor: theme.palette.grey[200],
  },

  [`& .${classes.drawerClose}`]: {
    backgroundColor: theme.palette.primary.light,
    transition: theme.transitions.create(["width", "background-color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter, //"300ms"
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
  },

  [`& .${classes.toolbarControls}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.white,
    padding: theme.spacing(0, 0),
    //...theme.mixins.toolbar,
    justifyContent: "center",
  },

  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.title}`]: {
    display: "none",
    fontFamily: "Pacifico",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  [`& .${classes.sectionDesktop}`]: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  [`& .${classes.sectionMobile}`]: {
    display: "flex",
  },

  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(4),
  },

  [`& .${classes.menuItem}`]: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  }
}));

const drawerWidth = 290;

function handleLogout(instance: IPublicClientApplication) {
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md
  let accounts = instance.getAllAccounts();

  // https://login.microsoftonline.com/duvalschoolsorg.onmicrosoft.com/oauth2/v2.0/logout?post_logout_redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F&client-request-id=d9bfc918-f3de-4240-8387-0721c395e749
  console.log('logging out', accounts);
  instance.logoutRedirect({
    account: accounts[0],
  }).catch(e => console.error(e));
}

export default function HomeRoomAppBar(sep: SearchEditorProps) {
  const user = useSelector((state: RootState) => state.user.user);
  const error = useSelector((state: RootState) => state.error.error);
  // only show search if we are on home
  let location = useLocation();
  let weAreHome = location.pathname === '/home' || location.pathname === '/';

  console.log('location', location.pathname);

  const theme = useTheme();
  const navigate = useNavigate();

  const { instance } = useMsal();

  let initAccounts = instance.getAllAccounts();

  if (authDiag) {
    console.log('accounts', initAccounts);
    console.log('config', instance.getConfiguration());
  }

  const hamburgerRef = useRef<HTMLButtonElement | null>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isSearchFocus, setIsSearchFocus] = React.useState<boolean>(false);

  const isMenuOpen = Boolean(anchorEl);
  let isSmallDevice = queryKnownMatchMedia('isSmallDevice') ?? false;
  const [open, setOpen] = React.useState(!isSmallDevice);
  const { t } = useTranslation();


  const people: IDocumentCardActivityPerson[] = [
    { name: user?.DisplayName, profileImageSrc: user?.PhotoUrl },
  ];

  const profileTranslated = t("Nav.Profile");

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (isSmallDevice) {
      setOpen(false);
    }
  };

  //right side menu function
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToggleDrawer = () => {
    setOpen((prev) => !prev);
  };
  const mobileMenuClose = (boo: any) => {
    console.log("nav Click", boo);
    setOpen(false);
  };

  const menuId = "primary-search-account-menu";

  // Open or close menus on resize
  const windowWidth = useRef<any>();
  useEventListener(window, "resize", () => {
    let currWidth = window.innerWidth;
    let lastWidth = windowWidth.current;

    if (currWidth !== lastWidth) {
      if (currWidth < 700 && open) {
        setOpen(false);
      }
      if (window.innerWidth > 1200 && !open) {
        setOpen(true);
      }
      windowWidth.current = window.innerWidth;
    }
  });

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  const tryExternalProfileUrl = (noEditLink: string) => {
    if (user?.CanEditProfile) {
      navigate(noEditLink);
    } else if (user?.ExternalProfileUrl) {
      windowOpen(user.ExternalProfileUrl);
    } else {
      // TODO: log this to SEQ
      console.error("bad external profile url", user?.ExternalProfileUrl);
    }
    handleMenuClose();
  }
  const loadingComponent = (componentName: string) => {
    if (componentName === "profile") {
      tryExternalProfileUrl("/mylinks/contactinfo");
    } else if (componentName === "password") {
      tryExternalProfileUrl("/mylinks/changepassword");
    } else if (componentName === "addstudent") {
      navigate("/mylinks/addstudent");
      handleMenuClose();
    }
  };
  usePullRight(() => {
    console.log("pull right");
    if (!open) {
      setOpen(true);
    }
  });

  useSwipeLeft(() => {
    console.log("swipe left");
    if (open) {
      setOpen(false);
    }
  });

  //right side drawer menu
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <div
        data-is-focusable={true}
        role="listitem"
        style={{ padding: 10, textAlign: "left", width: 280 }}
      >
        <div style={{ padding: 10 }}>
          <FluentUI.DocumentCardActivity activity={user?.Email} people={[people[0]]} />

          {user?.CanEditProfile || user?.HasExternalProfileUrl ? (
            <div>
              <MenuItem onClick={() => loadingComponent("profile")}>
                {t("Nav.My Profile")}
              </MenuItem>
              <MenuItem onClick={() => loadingComponent("password")}>
                {t("Nav.Change Password")}
              </MenuItem>
              {user?.HasStudentInfo && (
                <MenuItem onClick={() => loadingComponent("addstudent")}>
                  {t("Nav.Add Student")}
                </MenuItem>
              )}
            </div>
          ) : (
            ""
          )}

          <MenuItem onClick={() => handleLogout(instance)}>
            {t("Nav.Logout")}
          </MenuItem>
        </div>
      </div>
    </Menu>
  );

  // Close bar when click on background
  const handleClickAway = (e: any) => {
    // Exclude hamburger button itself from action
    if (
      isSmallDevice &&
      open &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(e.target)
    ) {
      e.stopPropagation();
      setOpen(false);
    }
  };

  //left side drawer menu
  const renderDrawer = (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={withEventTarget(handleClickAway)}
    >
      <Drawer
        variant={isSmallDevice ? "temporary" : "permanent"}
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onClose={toggleDrawer(false)}
      >
        <div className={classes.drawerHeader}></div>
        <Divider />

        {error.errorType === "menu" ? (
          <TokenError
            title="AppNavBar.menu"
            error={error}
          />
        ) : (
          <LeftNav
            drawerOpen={open}
            menuHandlerMobile={mobileMenuClose}
            screenSize={isSmallDevice}
          />
        )}
      </Drawer>
    </ClickAwayListener>
  );

  let appNotifications = (<div></div>);
  try {
    appNotifications = (<AppNotifications />);
  } catch (ex) {
    console.error("AppNotifications failure");
    // registerError(dispatch, ex, "notifications", false);

  }

  var diagC = null;
  if (user && user.IsImperator) {
    // diagC = <DiagPage user={user} />;
    diagC = (<IconButton key="diagPage" edge="end" component={Link} to="/diagPage">
      +

    </IconButton>);
  }

  return (
    (<Root className="AppNavBar_Root_HomeRoomAppBar" data-file="AppNavBar">
      {renderDrawer}
      <AppBar
        position="fixed"
        color="inherit"
        aria-label="open drawer"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <div className={classes.toolbarControls}>
            <div className={classes.drawerHeader}>
              <IconButton
                ref={hamburgerRef}
                aria-label={`${!open ? "open" : "close"} drawer`}
                onClick={handleToggleDrawer}
                edge="start"
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              {isSmallDevice || isSearchFocus ? '' :
                <IconButton
                  className={classes.drawerHeaderTitle}
                  disableRipple
                  component={Link}
                  to="/home"
                >
                  <OneViewWhite style={{ maxHeight: 35 }} />
                </IconButton>
              }
              {weAreHome ?
                <ContentSearch cardClassName={classes.homeSearchComponent} focus={[isSearchFocus, setIsSearchFocus]} onSearch={sep.onSearch} />
                : undefined}
            </div>
          </div>
          <div className={classes.grow} />

          <div className={classes.sectionMobile}>
            {/* <nav style={{ width: "100%", paddingTop: "10px" }}>
              <button onClick={() => changeLanguageHandler("en")}>
                English
              </button>
              <button onClick={() => changeLanguageHandler("span")}>
                Spanish
              </button>
            </nav> */}
            {diagC}
            {error.errorType === "notifications" ? (
              <TokenError
                title="AppNavBar.notifications"
                error={error}
              />
            ) : (appNotifications)}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              style={{ color: theme.palette.common.white }}
            >
              <Tooltip title={profileTranslated}>
                <div>
                  <AccountCircle />
                </div>
              </Tooltip>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {/* right side menu */}
      {renderMenu}
    </Root>)
  );
}
