// test at https://jsfiddle.net/boilerplate/typescript

// choosing to make key string only

export const setToLS = <T>(key: string, forceCreate: boolean, value: T | undefined) => {
  if (value === undefined && !forceCreate) {
    window.localStorage.removeItem(key);
  } else {
    // setting NaN or null stores : 'null'
    // setting the string "undefined" will blow up attempts to parse in getFromLS
    // typeScript insists you can't pass null or undefined as the key to setItem
    window.localStorage.setItem(key, value === undefined ? "" : JSON.stringify(value));
  }
};

// this does not work for NaN
// setting NaN or null returns {undefined: false, isNull: true}
export const getFromLS = <T>(key: string): T | undefined => {
  const value = window.localStorage.getItem(key);

  if (value === "" || value === undefined || value === "undefined")
    return undefined;

  // tested using false, apparently it works this way
  // because getItem returns 'false', not false
  if (value) {
    try {
      return JSON.parse(value) as T;
    } catch (ex) {
      console.error("getFromLS:" + key, ex);
    }
  }
};

// create stub: plug in the feature flag into local storage without defining a value
// allows people to still improperly set the flag manually, but creates the stub automatically
// might be nice to have this feature only allowed in dev
export const createLSFeatureFlag = (key: string, createStub: boolean, defaultValue: boolean): { getter: () => boolean, setter: (value: boolean | undefined) => void } => {
  let getter: (() => boolean) = () => {
    let value: boolean | undefined = getFromLS(key);
    let hasValue = value === false || value === true;
    if (!hasValue && createStub) {
      setToLS(key, true, undefined);
    }
    const result = hasValue ? value as boolean : defaultValue;
    // console.log(key, { hasValue, result });

    return result;
  };

  return {
    getter: getter,
    setter: next => {
      setToLS(key, createStub, next);
    }
  }
};


export const setToSS = <T>(key: string, value: T) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSS = <T>(key: string): T | undefined => {
  const value = window.sessionStorage.getItem(key);

  if (value) {
    return JSON.parse(value) as T;
  }
};

export function removeLSItems(regEx: any) {
  Object.keys(localStorage).forEach((key) => {
    if (regEx.test(key)) {
      localStorage.removeItem(key);
    }
  });
}

export function removeSSItems(regEx: any) {
  Object.keys(sessionStorage).forEach((key) => {
    if (regEx.test(key)) {
      sessionStorage.removeItem(key);
    }
  });
}
