// index:App:TokenComponent:AppRoot ( auth required to reach this point)
import { Suspense, lazy, useRef, useState } from "react";
import { Route, HashRouter, Routes, Navigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CssBaseline } from "@mui/material";
import { RootState } from "../store";
import ThemeProvider from "../style/Theme";
import { ErrorInterface } from "../store/error-slice";
import "../style/App.css";
import { System, equalsCI } from "../Reusable";
import { RenameComponent } from "../components/Rename";
import { SearchInvokeAsync, ServerErrorResponse, UserInterface } from "../adapters/ApiSchema";
import { actionItemRemote } from "../components/ActionItemsWrapper";
import LinksComponent from "../components/LinksComponent/LinksComponent";
import defaultStore from "../util/Globale";
import { DiagPage } from "../pages/DiagPage";
import { SearchEditorProps, SearchInvokeRef } from "../pages/Home/CDS";

const AppNavBar = lazy(() => import("../components/appBar/AppNavBar"));
const AppFooter = lazy(() => import("../components/appBar/AppFooter"));


const HomeRoomContactInfo = lazy(() => import("../pages/MyLinks/UserProfileSettings/ContactInfo"));
const HomeRoomSecuritySettings = lazy(() => import("../pages/MyLinks/UserProfileSettings/SecuritySettings"));
const AppAddStudent = lazy(() => import("../pages/MyLinks/StudentProfile/AddStudent"));


const AppMySchools = lazy(() => import("../pages/MySchools/MySchools"));
const AppHome = lazy(() => import("../pages/Home/Home"));
const POMAdmin = lazy(() => import("../pages/PartnerManager/PartnerManager"));


const StudentInfo = lazy(() => import("../pages/MyStudentInfo/MyStudentInfo"));
const StudentProfile = lazy(() => import("../pages/MyLinks/StudentProfile/StudentProfile"));

const MyDevices = lazy(() => import("../pages/Devices/MyDevices"));
const MyStudentDevices = lazy(() => import("../pages/Devices/MyStudentDevices"));
const MyApps = lazy(() => import("../pages/MyApps/MyApps"));
const MyStudentContactInfo = lazy(() => import("../pages/MyStudentContactInfo/MyStudentContactInfo"));

const GenericNotFound = lazy(() => import("../pages/Exceptions/GenericNotFound"));

const TokenError = lazy(() => import("../pages/Exceptions/TokenError"));
const DefaultError = lazy(() => import("../pages/Exceptions/DefaultError"));

// maybe revise before prod
// should check for message field, or exception message field, otherwise unknown
const DefaultErrorComp = ({ user, error }: { user: UserInterface | undefined, error: ErrorInterface }) => (<div>
  <DefaultError />
  <ul>
    <li>
      <label>Error</label>
      <p>
        {JSON.stringify(error, null, 2)}
      </p>
    </li>
    <li>
      <label>User</label>
      <p>
        {user !== undefined ? JSON.stringify(user, null, 2) : "no user"}
      </p>
    </li>
  </ul>
</div>);

function RouterRepair2() {
  const l = window.location
  let next = l.protocol + "//" + l.host + "/#" + l.pathname + l.search;
  window.location.assign(next);
}

function RouteRepair(props: { target?: string, replacement?: string }) {
  const values = useParams();
  const loc = useLocation();

  if (props.target && props.replacement) {
    // case insensitive replace
    let regex = new RegExp(props.target, "i");

    let toPath = loc.pathname.replace(regex, props.replacement);
    let locDiagnostics = {
      to: toPath,
      loc,
      location: {
        toString: window.location.toString(),
        pathname: window.location.pathname,
        hash: window.location.hash,
        '!hash': !window.location.hash
      },
      params: values
    };
    console.log("RouteRepair", locDiagnostics);
    //   console.log('navigating to ', toPath);
    return (<Navigate to={toPath} replace />)
  } else return (<div>Unknown routing error</div>);
}

let ExternalRedirect = (props: { to: string }) => {
  window.location.href = props.to;
  return null;
}

let getTokenError = (error: ErrorInterface) => (
  <TokenError
    title="AppRoot"
    error={error}
  />);

let tokenErrorIfType = (eType: string, error: ErrorInterface, validComponent: JSX.Element) => {
  if (equalsCI(eType, error.errorType))
    return getTokenError(error);
  return validComponent;
}


function MyLinksRouter(props: { user?: UserInterface, error: ErrorInterface }) {
  let error = props.error;

  return (
    <Routes>
      <Route path="/" element={tokenErrorIfType("menu", error, <LinksComponent category="My Links" data-file="AppRoot" />)} />
      <Route path="/addStudent" element={<AppAddStudent />} />
      <Route
        path="/studentProfile"
        element={<StudentProfile />}
      />
      {/* <Route path="/ferpa" element={<PomParents />} /> */}
      <Route path="/ferpa" element={<ExternalRedirect to={defaultStore.ferpa} />} />
      {/* Error handling within the component */}
      <Route
        path="/contactInfo"
        element={<HomeRoomContactInfo />}
      />
      {/* Error handling within the component */}
      <Route
        path="/changePassword"
        element={<HomeRoomSecuritySettings />}
      />

      <Route path="/pomAdmin" element={<POMAdmin />} />
      <Route path="/myInfo/rename" element={<RenameComponent username={props.user?.Email ?? ""} />} />
    </Routes>
  );
}

// allows us to switch between hash router and browser router if desired
// in case we ever need to switch back to browser router
const routerMeat = (token: string, user: UserInterface | undefined, sep: SearchEditorProps & SearchInvokeRef, error: ErrorInterface) => {
  // <Route path="/My Links/*" element={<RouteRepair target="My%20Links" replacement="MyLinks" />} />
  // <Route path="/My Devices/*" element={<RouteRepair target="My%20Devices" replacement="MyDevices" />} />
  let spaceReplacers = [
    "My Apps",
    "My Links",
    "How To",
    "Blended Learning",
    "My Schools",
    "My Info",
    "My Devices"
  ];


  let spaceRoutes = spaceReplacers.map(text => {

    let wildPath = `/${text}/*`;
    let target = text.replace(/ /, '%20');
    let replacement = text.replace(/ /, '');
    return (<Route key={wildPath} path={wildPath} element={<RouteRepair target={target} replacement={replacement} />} />);
  });

  return (
    <div className={"drawerController"} data-file="AppRoot">
      <ThemeProvider>
        <CssBaseline />
        <AppNavBar {...sep} />
        <main className={"mainDivRouter"}>
          {error.fatal ? (
            token === "" ? getTokenError(error) : (
              <DefaultErrorComp user={user} error={error} />
            )
          ) : (
            <Suspense fallback={<></>}>
              <Routes>
                <Route path="/" element={<AppHome {...sep} />} />
                {
                  spaceRoutes
                }
                {/* Error handling within the component */}
                <Route path="/home" element={<AppHome  {...sep} />} />

                <Route path='/HowTo' element={tokenErrorIfType("menu", error, <LinksComponent category="How To" data-file="AppRoot" />)} />

                <Route path="/BlendedLearning" element={tokenErrorIfType("menu", error, <LinksComponent category="Blended Learning" />)} />
                <Route path="/MyApps" element={<MyApps />} />
                <Route path="/diagPage" element={<DiagPage user={user} />} />

                <Route path="/MyLinks/*" element={<MyLinksRouter user={user} error={error} />} />

                {/* Error handling within the component */}
                <Route path="/MySchools" element={<AppMySchools />} />

                <Route path="/MyInfo/rename" element={<RenameComponent username={user?.Email ?? ""} />} />
                <Route path="/MyStudentInfo" element={tokenErrorIfType("student", error, <StudentInfo />)} />
                <Route path="/myInfo" element={tokenErrorIfType("student", error, <StudentInfo />)} />
                <Route path="/MyStudentContactInfo" element={tokenErrorIfType("student", error, <MyStudentContactInfo />)} />

                {/* Error handling within the component */}
                <Route path="/MyDevices" element={<MyDevices />} />

                <Route path="/MyStudentDevices" element={tokenErrorIfType("stuDevices", error, <MyStudentDevices />)} />
                <Route path="*" element={<GenericNotFound />} />
              </Routes>
            </Suspense>
          )}
          <AppFooter />
        </main>
      </ThemeProvider>
    </div>
  );
};
// will this cause an attempt before the user is initialized
// authentication root. this component will only be rendered once authenticated
const AppRoot = () => {

  console.log('AppRoot render');
  // const [searchText, setSearchText] = useState<string>("");
  const [lastSearch, setLastSearch] = useState<string>("");
  // takes in nothing, and returns a promise that will let us know if the search worked or there was an error
  let cdsRef = useRef<SearchInvokeAsync | null>(null);
  let onSearch = (searchText: string) => {// new Promise(resolve => {
    if (!cdsRef || cdsRef.current == null) {
      console.warn("no cds ref found");
      return Promise.reject("no cds ref found");
    } else {
      console.log("invoke search ref");
      return cdsRef.current(searchText);
    }
  };

  let sep: SearchEditorProps & SearchInvokeRef = { onSearch: onSearch, searchInvokeRef: cdsRef };

  const token = useSelector((state: RootState) => state.token.token);
  const error = useSelector((state: RootState) => state.error.error);
  const user = useSelector((state: RootState) => {
    try {
      let u = state.user.user;
      if (!!u) {
        // console.log('user grab success', u.UserPrincipalName);
      } else {
        console.warn('null user');
      }
      return u;
    } catch (ex) {
      console.error('user grab failure', ex);
    }
  });
  if (user) {
    // initializeActionItems();
    actionItemRemote.Initialize();
  }
  // only use url router if there is a path other than root without a hash
  if (window.location.pathname && window.location.pathname !== "/" && !window.location.hash) {
    console.log('using redirect router', window.location.pathname, window.location.hash);
    RouterRepair2();
    // return (<BRouter />);
  }

  return (
    <HashRouter data-file="AppRoot">
      {
        routerMeat(token, user, sep, error)
      }
    </HashRouter>
  );
};

export default AppRoot;
