import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";

import store from "../src/store/index";

import "./index.css";
import "./util/i18next";
import App from "./App";

import { OptOutComponent } from "./components/OptOut";

import * as serviceWorker from "./serviceWorker";
import { System } from "./Reusable";

// const redirectUri = window.location.origin;
const msalConfig = {
  auth: {
    clientId: "ef364ce3-933b-4a08-b5c7-6039fc2372c5",

    authority:
      "https://login.microsoftonline.com/duvalschoolsorg.onmicrosoft.com",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

const SiteHooks = () => {

  if (System.String.containsCI("optOut", window.location.href))
    return (<OptOutComponent isIn={false} />);

  if (System.String.containsCI("optIn", window.location.href))
    return (<OptOutComponent isIn={true} />);

  return (
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <Suspense fallback="loading">
            <App data-file="index" />
          </Suspense>
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  );

}
ReactDOM.render(
  <SiteHooks />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
