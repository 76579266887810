import { Result, addQueryParams, resultError, resultOk } from "../Reusable";

export function formatDateD(_date: Date): string {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let timeStamp = _date.toLocaleString().split(",")[1];
  let formatted_date =
    _date.getDate() +
    " " +
    months[_date.getMonth()] +
    " " +
    _date.getFullYear();

  return formatted_date + timeStamp;
}

export function formatDate(_date: string): string {
  let current_dateTime = new Date(_date);
  return formatDateD(current_dateTime);
}

const regex = "^((([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+(\\.([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+)*)|((\\x22)((((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(([\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x7f]|\\x21|[\\x23-\\x5b]|[\\x5d-\\x7e]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(\\\\([\\x01-\\x09\\x0b\\x0c\\x0d-\\x7f]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF]))))*(((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(\\x22)))@((([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.)+(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-|\\.|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.?$";
let re = new RegExp(regex);
export function validateEmail(email: string): Result<string, string> {
  // new Regex(regex, RegexOptions.IgnoreCase | RegexOptions.ExplicitCapture)
  if (re.test(email))
    return resultOk(email);
  return resultError("Email failed validation");
}

export const createImpersonateUrlBuilder = (hostName: string, upnOverride: string | undefined) => (relPath: string, queryParams?: { key: string, value: string }[]) => {
  // if there is a upnOverride or any query params run the adder
  if (!!upnOverride || (queryParams && queryParams.length > 0)) {
    var qp = !!upnOverride ? [{ key: "upn", value: upnOverride }, ... (queryParams ?? [])] : queryParams ?? [];
    // export const addQueryParams = (url: string, values: { key: string, value: string }[]) => {
    return addQueryParams(hostName + relPath, qp);
  }
  return hostName + relPath;

};