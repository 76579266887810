import { addComponentClasses } from '../Reusable';

import { Icon as FluentIcon } from "@fluentui/react/lib/Icon";

import { IDocumentCardActivityProps, DocumentCardActivity as FluentDocumentCardActivity } from "@fluentui/react/lib/DocumentCard";

import { IDocumentCardActionsProps, DocumentCardActions as FluentDocumentCardActions } from "@fluentui/react/lib/DocumentCard";
import { IButtonProps, PrimaryButton as FluentPrimaryButton } from "@fluentui/react/lib/Button";

import { useId as fluentUseId, useBoolean as fluentUseBoolean } from "@fluentui/react-hooks";

import {
  getTheme,
  mergeStyleSets,
  Modal,
  IconButton as FluentIconButton,
  IIconProps,
} from "@fluentui/react";

export type { IDocumentCardActivityPerson } from "@fluentui/react/lib/DocumentCard";

let fluentUIClass = "fluentUI"
let makeFluentProps = <T,>(name: string, props: T & typeof addComponentClasses.arguments[0]) => ({ ...addComponentClasses(props, [fluentUIClass, `${fluentUIClass}_${name}`]) });

export namespace FluentUI {
  export const useId = fluentUseId
  export const useBoolean = fluentUseBoolean

  // export const DocumentCardActions = (props: typeof addComponentClasses.arguments[0] & typeof FluentDocumentCardActions.arguments[0]) => <FluentDocumentCardActions {...addComponentClasses(props, [fluentUIClass, "fluentUI.documentCardActions"])} />
  export const DocumentCardActions = (props: IDocumentCardActionsProps) => <FluentDocumentCardActions {...makeFluentProps<IDocumentCardActionsProps>("documentCardActions", props)} />
  export const DocumentCardActivity = (props: IDocumentCardActivityProps) => <FluentDocumentCardActivity {...makeFluentProps("documentCardActivity", props)} />
  export const PrimaryButton = (props: IButtonProps) => <FluentPrimaryButton {...makeFluentProps("PrimaryButton", props)} />
  export const Icon = (props: IIconProps) => <FluentIcon {...makeFluentProps("icon", props)} />
}

