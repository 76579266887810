import React from "react"
import { Collapsible } from "./CollapsibleList";
import { isArray } from "../Reusable";

// attempt to explore the method overload system to the benefit of display outputs
// interface DumpProps {value: string}
// export class Dump extends React.Component<DumpProps,unknown,unknown>{
//     constructor(props:DumpProps){
//         super(props);
//     }
// }

// https://stackoverflow.com/questions/749266/object-dump-javascript
// https://stackoverflow.com/questions/51439843/unknown-vs-any
// function recDump(value:unknown){

// }

// desired feature, clickable expand/collapse trees and all
export function dump(wrap: boolean, value: unknown) {
    // let heart = JSON.stringify(value);
    let heart: string | JSX.Element = "<unknown>";
    if (value === undefined) {
        heart = "<undefined>";
    } else if (value === null) {
        heart = "<null>";
    } else if (typeof value === 'string') {
        heart = value === "" ? "<empty>" : value;
    } else if (isArray(value)) {
        let createCollapsible = (arrayType: string, items: (string | JSX.Element)[]) => <Collapsible data-arrayType={arrayType} collapsibleProps={{ content: { state: 'enumerable', items } }} />;
        if (value.every(x => React.isValidElement(x as {}))) {
            heart = createCollapsible('element', value as JSX.Element[]); // <Collapsible collapsibleProps={{content:{state:'enumerable', items:value}}} />;
        } else if (value.every(x => typeof x === 'string')) {
            heart = createCollapsible('string', value.map(x => x as string)); // <Collapsible collapsibleProps={{content:{state:'enumerable', items:}}} />;
        } else {
            heart = createCollapsible('unknown', value.map(x => JSON.stringify(x)));  // <Collapsible collapsibleProps={{content:{state:'enumerable', items:}}} data-arrayType={'unknown'} />;
        }
    } else if (typeof value === 'object') {
        let keys = Object.keys(value as object);
        heart = <Collapsible data-type='object' collapsibleProps={{ content: { state: 'enumerable', items: keys } }} />
    }
    if (wrap) {
        return <div>{heart}</div>
    }
    else {
        return (
            <React.Fragment>
                {heart}
            </React.Fragment>
        )
    }
}
