import { System } from "../Reusable";

export type JsEvent = {
    stopPropagation: System.Action
    preventDefault: System.Action
    target: any
    currentTarget: any
}

export let withEventTarget = (f: System.Action1<any>) => (e: JsEvent) => f(e.target);
export let withValueChange = (f: System.Action1<any>) => (e: { value: any }) => f(e.value)
export let withEventTargetValue = (f: System.Action1<any>) => (e: JsEvent) => f(e.target.value);
export let withScrollingTarget = (f: System.Action1<any>) => (e: JsEvent) => f(e.target.scrollingElement);

export let getEventTargetValue = (e: JsEvent) => e.target.value;

export let withPreventDefault = (f: System.Action1<JsEvent>) => (e: JsEvent) => { e.preventDefault(); f(e); }

export let withEventMouseTarget = (e: JsEvent | React.MouseEvent<HTMLButtonElement>) => (f: System.Action1<any>) => {
    f(e.currentTarget);
};