import React, { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { truncateString } from "../Reusable";
import { withPreventDefault } from "../adapters/Eventing";
import { getComputedStylePropertyValue } from "../adapters/windowUtils";
import { dump } from "./DumpComponent";

type RenderULSpecProps = { items: (JSX.Element | string)[] }

const RenderUL = (props: { renderUlProps: RenderULSpecProps } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>) => {
    let { renderUlProps, ...ulProps } = props;

    return <ul {...ulProps} >
        {
            renderUlProps.items.map((item, i) => <li key={i}>{item}</li>)
        }
    </ul>
}
export type CollapsibleString = {
    state: 'string'
    // does a default cut off make sense?
    maxLength: number
    content: string
}
export type CollapsibleEnumerable = {
    state: 'enumerable'
    items: (JSX.Element | string)[]
}

export type CollapsibleType =
    | CollapsibleString
    | CollapsibleEnumerable

export type CollapsibleSpecProps = { isOpenDefault?: boolean, content: CollapsibleType }

// considering using https://mui.com/material-ui/api/collapse/ instead
// handle a list of items, and potentially large text
export const Collapsible = (props: { collapsibleProps: CollapsibleSpecProps } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    let { collapsibleProps, ...divProps } = props;

    const docColorRef: MutableRefObject<string | null> = useRef<string>(null);
    // const aRef = useRef<HTMLAnchorElement>(null);

    // need state to trigger re-render if it changes
    const [linkColors, setLinkColors] = useState<string | undefined>(() => docColorRef.current ?? undefined);

    const setLinkColorCb = useCallback((node: HTMLElement | null) => {
        if (node === null || linkColors !== undefined) return;
        if (docColorRef.current) {
            setLinkColors(docColorRef.current);
            return;
        }
        let color = getComputedStylePropertyValue(node, "color");
        if (color === undefined) return;
        docColorRef.current = color;
        setLinkColors(color);
    }, []);

    // https://stackoverflow.com/questions/64414820/force-link-to-look-not-visited-using-css
    const [open, setOpen] = useState(collapsibleProps.isOpenDefault ?? false);
    // perhaps later widen to any iterable?
    // const renderArray= (items:(JSX.Element| string)[], expanded:boolean) => {
    const renderArray = (e: CollapsibleEnumerable, expanded: boolean) => {
        if (expanded) {
            let items2 = e.items.map(item => React.isValidElement(item) ? item : dump(false, item));
            return (<RenderUL renderUlProps={{ items: items2 }} />);
        } else return (<div />);
    }

    const renderString = (cs: CollapsibleString, expanded: boolean) => {
        if (expanded || (!expanded && cs.content && cs.content.length <= cs.maxLength)) {
            return <div>{cs.content}</div>
        } else {
            return <div>{truncateString(cs.content, cs.maxLength)}</div>
        }
    }

    return ( // aria-expanded isn't allowed on a div
        <div data-open={JSON.stringify(open)} {...divProps} >
            <a aria-controls="collapse-text" ref={setLinkColorCb} style={linkColors !== undefined ? { color: linkColors } : {}} href="#" onClick={withPreventDefault(() => setOpen(!open))}>{open ? '-' : '+'}</a>
            {
                collapsibleProps.content.state === 'string' ? renderString(collapsibleProps.content, open) :
                    collapsibleProps.content.state === 'enumerable' ? renderArray(collapsibleProps.content, open) :
                        'unknown collapsible type'
            }
        </div>
    )
}